<template>
  <a-layout-content>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem v-if="fromPageName === 'PublicDeliver'">
          <span @click="onBackDelivery">发货</span>
        </BreadcrumbItem>
        <BreadcrumbItem v-if="fromPageName === 'FactoryReturnedExamine'">
          <span @click="onBack">订单审核</span>
        </BreadcrumbItem>
        <BreadcrumbItem>地址库管理</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="info-container">
      <a-row>
        <a-col>
          <div v-if="!editStatus" class="info-title">添加新地址</div>
          <div v-else class="info-title">修改地址</div>
        </a-col>
      </a-row>
      <a-form :form="newAddressForm" class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="所在地区">
              <a-cascader
                v-decorator="[
                  'areaCode',
                  { rules: [{ required: true, message: '请输入所在地区' }] }
                ]"
                :options="areaTreeData"
                :field-names="areaTreeNames"
                placeholder="请选择区域"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="详细地址">
              <a-input
                v-decorator="[
                  'detailAddress',
                  {
                    rules: [{ required: true, message: '请输入详细地址' }]
                  }
                ]"
                placeholder="请输入详细地址"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="联系人姓名">
              <a-input
                v-decorator="[
                  'senderName',
                  {
                    rules: [{ required: true, message: '请输入联系人姓名' }]
                  }
                ]"
                placeholder="请输入联系人姓名"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="联系人电话">
              <a-input
                v-decorator="[
                  'senderTel',
                  {
                    rules: [{ required: true, message: '请输入联系人电话' }]
                  }
                ]"
                placeholder="请输入联系人电话"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-button
          v-if="!editStatus"
          type="primary"
          class="btn-save"
          @click="onNew"
        >
          保存
        </a-button>
        <a-button v-else type="primary" class="btn-save" @click="onModify">
          保存修改
        </a-button>
      </a-form>
    </section>
    <section v-if="!editStatus" class="address-container">
      <a-row>
        <a-col><div class="info-title">地址列表</div></a-col>
      </a-row>
      <a-table
        class="commodity-table"
        :data-source="tableData"
        :columns="tableColumns"
        :loading="loading"
        row-key="addressId"
        :pagination="false"
      >
        <template slot="defaultFlag" slot-scope="text, record">
          <a-radio :checked="record.defaultFlag" @focus="onFocus(record)">
            默认
          </a-radio>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space>
            <a @click="onEdit(record.addressId, record.senderId)">编辑</a>
            <a-popconfirm
              title="确认是否删除?"
              ok-text="确定"
              cancel-text="取消"
              @confirm="onDelete(record.senderId)"
            >
              <a>删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
    </section>
  </a-layout-content>
</template>

<script>
import { loadArea } from "../../services/DealerService";
import {
  addSenderAddress,
  deleteSenderAddress,
  fetchAddressDetail,
  fetchAddressList,
  modifySenderAddress
} from "../../services/OrderService";
import BreadcrumbItem from "@/components/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb";

const tableColumns = [
  {
    title: "发货地址",
    dataIndex: "defaultFlag",
    scopedSlots: { customRender: "defaultFlag" }
  },
  {
    title: "所在地区",
    dataIndex: "area"
  },
  {
    title: "详细地址",
    dataIndex: "detailAddress"
  },
  {
    title: "联系人姓名",
    dataIndex: "senderName"
  },
  {
    title: "联系人电话",
    dataIndex: "senderTel"
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" }
  }
];

export default {
  components: { Breadcrumb, BreadcrumbItem },
  data() {
    return {
      newAddressForm: this.$form.createForm(this),
      areaCode: [],
      areaTreeData: [],
      areaTreeNames: {
        label: "areaName",
        value: "areaCode",
        children: "subAreas"
      },
      tableData: [],
      tableColumns,
      loading: false,
      editStatus: false,
      addressId: "",
      senderId: "",
      procureOrderSn: this.$route.params.procureOrderSn,
      fromPageName: ""
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromPageName = from.name;
    });
  },
  mounted() {
    this.onLoadArea();
    this.onFetchList();
  },
  methods: {
    // 返回上一级
    onBack() {
      this.$router.go(-1);
    },
    onBackList() {
      this.$router.replace({ name: "UndeliveredOrder" });
    },
    onLoadArea() {
      this.areaTreeData = [];
      loadArea().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.areaTreeData = resp.data.data;
        }
      });
    },
    onFetchList() {
      const factoryId = localStorage.getItem("factoryId");
      fetchAddressList(factoryId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (data.length > 0) {
            data.forEach(item => {
              if (
                item.senderProvinceName ||
                item.senderCityName ||
                item.senderCountyName
              )
                if (item.senderProvinceName === item.senderCityName) {
                  item.area = `${item.senderCityName} ${item.senderCountyName}`;
                } else {
                  item.area = `${item.senderProvinceName} ${item.senderCityName} ${item.senderCountyName}`;
                }
              if (data.defaultFlag) {
                item.isDefault = true;
              } else {
                item.isDefault = false;
              }
            });
          }
          this.tableData = data;
        }
      });
    },
    // 切换修改状态
    onEdit(addressId, senderId) {
      this.addressId = addressId;
      this.senderId = senderId;
      this.editStatus = true;
      fetchAddressDetail(addressId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.newAddressForm.setFieldsValue({
            areaCode: [data.provinceCode, data.cityCode, data.countyCode]
          });
          this.newAddressForm.setFieldsValue({
            detailAddress: data.detailAddress
          });
          this.newAddressForm.setFieldsValue({ senderName: data.senderName });
          this.newAddressForm.setFieldsValue({ senderTel: data.senderTel });
        }
      });
    },
    // 新增地址保存
    onNew() {
      this.newAddressForm.validateFields((err, values) => {
        if (!err) {
          if (values.senderTel.length !== 11) {
            this.$message.info("请输入正确的手机号");
          } else {
            const areaCode = values.areaCode;
            const params = {
              provinceCode: areaCode[0],
              cityCode: areaCode[1],
              countyCode: areaCode[2],
              defaultFlag: false,
              senderName: values.senderName,
              senderTel: values.senderTel,
              factoryId: localStorage.getItem("factoryId"),
              detailAddress: values.detailAddress
            };
            addSenderAddress(params).then(resp => {
              if (resp.data.code === "SUCCESS") {
                this.$message.success("新增成功");
                this.onFetchList();
                this.newAddressForm.resetFields();
              }
            });
          }
        }
      });
    },
    onDelete(senderId) {
      deleteSenderAddress(senderId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("删除成功");
          this.onFetchList();
        }
      });
    },
    onModify() {
      this.newAddressForm.validateFields((err, values) => {
        if (!err) {
          if (values.senderTel.length !== 11) {
            this.$message.info("请输入正确的手机号");
          } else {
            const areaCode = values.areaCode;
            const params = {
              addressId: this.addressId,
              provinceCode: areaCode[0],
              cityCode: areaCode[1],
              countyCode: areaCode[2],
              defaultFlag: false,
              senderName: values.senderName,
              senderTel: values.senderTel,
              factoryId: localStorage.getItem("factoryId"),
              detailAddress: values.detailAddress,
              senderId: this.senderId
            };
            modifySenderAddress(params).then(resp => {
              if (resp.data.code === "SUCCESS") {
                this.$message.success("修改成功");
                this.editStatus = false;
                this.onFetchList();
                this.newAddressForm.resetFields();
              }
            });
          }
        }
      });
    },
    onFocus(record) {
      const params = {
        addressId: record.addressId,
        provinceCode: record.provinceCode,
        cityCode: record.cityCode,
        countyCode: record.countyCode,
        defaultFlag: true,
        senderName: record.senderName,
        senderTel: record.senderTel,
        factoryId: localStorage.getItem("factoryId"),
        detailAddress: record.detailAddress,
        senderId: record.senderId
      };
      modifySenderAddress(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("设置成功");
          this.onFetchList();
        }
      });
    },
    onBackDelivery() {
      this.$router.go(-1);
    },
    onBackDetail() {
      this.$router.replace({
        name: "UndeliveredDetail",
        params: { procureOrderSn: this.procureOrderSn }
      });
    }
  }
};
</script>

<style scoped>
.info-container {
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
  margin: 10px 20px 0 20px;
  background-color: white;
}
.info-title {
  font-weight: bold;
  margin-bottom: 20px;
}
/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 100px !important;
}
/deep/ .ant-form-item-control {
  width: 90%;
}
.btn-save {
  margin-left: 100px;
}
.commodity-table {
  margin-bottom: 20px;
}
.address-container {
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
  margin: 10px 20px 10px 20px;
  background-color: white;
}
</style>
