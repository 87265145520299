var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-content',[_c('div',{staticClass:"nav"},[_c('Breadcrumb',[(_vm.fromPageName === 'PublicDeliver')?_c('BreadcrumbItem',[_c('span',{on:{"click":_vm.onBackDelivery}},[_vm._v("发货")])]):_vm._e(),(_vm.fromPageName === 'FactoryReturnedExamine')?_c('BreadcrumbItem',[_c('span',{on:{"click":_vm.onBack}},[_vm._v("订单审核")])]):_vm._e(),_c('BreadcrumbItem',[_vm._v("地址库管理")])],1)],1),_c('section',{staticClass:"info-container"},[_c('a-row',[_c('a-col',[(!_vm.editStatus)?_c('div',{staticClass:"info-title"},[_vm._v("添加新地址")]):_c('div',{staticClass:"info-title"},[_vm._v("修改地址")])])],1),_c('a-form',{staticClass:"advanced-search-form",attrs:{"form":_vm.newAddressForm}},[_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"所在地区"}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'areaCode',
                { rules: [{ required: true, message: '请输入所在地区' }] }
              ]),expression:"[\n                'areaCode',\n                { rules: [{ required: true, message: '请输入所在地区' }] }\n              ]"}],attrs:{"options":_vm.areaTreeData,"field-names":_vm.areaTreeNames,"placeholder":"请选择区域"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"详细地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'detailAddress',
                {
                  rules: [{ required: true, message: '请输入详细地址' }]
                }
              ]),expression:"[\n                'detailAddress',\n                {\n                  rules: [{ required: true, message: '请输入详细地址' }]\n                }\n              ]"}],attrs:{"placeholder":"请输入详细地址"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"联系人姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'senderName',
                {
                  rules: [{ required: true, message: '请输入联系人姓名' }]
                }
              ]),expression:"[\n                'senderName',\n                {\n                  rules: [{ required: true, message: '请输入联系人姓名' }]\n                }\n              ]"}],attrs:{"placeholder":"请输入联系人姓名"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"联系人电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'senderTel',
                {
                  rules: [{ required: true, message: '请输入联系人电话' }]
                }
              ]),expression:"[\n                'senderTel',\n                {\n                  rules: [{ required: true, message: '请输入联系人电话' }]\n                }\n              ]"}],attrs:{"placeholder":"请输入联系人电话"}})],1)],1)],1),(!_vm.editStatus)?_c('a-button',{staticClass:"btn-save",attrs:{"type":"primary"},on:{"click":_vm.onNew}},[_vm._v(" 保存 ")]):_c('a-button',{staticClass:"btn-save",attrs:{"type":"primary"},on:{"click":_vm.onModify}},[_vm._v(" 保存修改 ")])],1)],1),(!_vm.editStatus)?_c('section',{staticClass:"address-container"},[_c('a-row',[_c('a-col',[_c('div',{staticClass:"info-title"},[_vm._v("地址列表")])])],1),_c('a-table',{staticClass:"commodity-table",attrs:{"data-source":_vm.tableData,"columns":_vm.tableColumns,"loading":_vm.loading,"row-key":"addressId","pagination":false},scopedSlots:_vm._u([{key:"defaultFlag",fn:function(text, record){return [_c('a-radio',{attrs:{"checked":record.defaultFlag},on:{"focus":function($event){return _vm.onFocus(record)}}},[_vm._v(" 默认 ")])]}},{key:"action",fn:function(text, record){return [_c('a-space',[_c('a',{on:{"click":function($event){return _vm.onEdit(record.addressId, record.senderId)}}},[_vm._v("编辑")]),_c('a-popconfirm',{attrs:{"title":"确认是否删除?","ok-text":"确定","cancel-text":"取消"},on:{"confirm":function($event){return _vm.onDelete(record.senderId)}}},[_c('a',[_vm._v("删除")])])],1)]}}],null,false,2995263036)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }